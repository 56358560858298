<nz-layout>
    <!-- <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <div class="logo">
            <div>
                <img *ngIf="!isCollapsed" src="./../../assets/images/ktern.PNG" class="logo-big" alt="KTern Logo"
                    style="padding-top:0px;" />
                <img *ngIf="isCollapsed" src="./../../assets/images/ktern-icon.png" class="logo-small" alt="KTern Logo"
                    style="width: 40px; height: 40px" />
            </div>
        </div>
        <ul nz-menu nzTheme="dark" nzMode="inline">
            <li nzSelected nz-menu-item>
                <span nz-icon nzType="project"></span>
                <span>Planner</span>
            </li>
        </ul>
    </nz-sider> -->
    <nz-layout>
        <nz-header>
            <div class="row" style="margin-right: 10px;">
                <div class="col-md-6 d-flex flex-row">
                    <!-- <div>
                        <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                            (click)="isCollapsed = !isCollapsed"></span>
                    </div> -->
                    <div style="margin-right: 12px;">
                        <img src="./../../assets/images/ktern.PNG" class="logo-big" alt="KTern Logo" (click)="navigateToSimulation()"/>
                    </div>
                    <!-- <div>
                        <nz-select ngModel="motiva" nzDisabled>
                            <nz-option nzValue="motiva" nzLabel="Motiva Quantum Leap"></nz-option>
                        </nz-select>
                    </div> -->
                </div>
                <div class="col-md-6 d-flex flex-row justify-content-end">
                    <div style="margin-right: 8px;">
                        <nz-avatar [nzText]="getInitials(userName)"
                            style="color:#f56a00; background-color:#fde3cf;"></nz-avatar>
                    </div>
                    <div>
                        {{userName}}
                    </div>
                </div>
            </div>
        </nz-header>
        <nz-content>
            <router-outlet></router-outlet>
        </nz-content>
    </nz-layout>
</nz-layout>