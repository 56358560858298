import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { _data } from "./gantt-data";
import { _data } from './launch-saas';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { catchError, throwError } from 'rxjs';
import { Router, NavigationExtras } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class GanttDataService {

  constructor(private http: HttpClient, private auth: AuthService, private route: Router, private message: NzMessageService) {
    this.projectID = this.auth.getProjectid();
    this.token = this.auth.getktoken();
    console.log("Got token from cookie", this.token)
  }

  // tasksData = _data[0].tasks.rows

  // linksData = _data[0].dependencies.rows

  // resourcesData = _data[0].resources.rows

  // assignmentsData = _data[0].assignments.rows

  token: string;

  getGanttData() {
    // return this.http.get(`${environment.apiBaseUrl}motivaData`);
    return this.http
      .get(`http://localhost:3000/projectmanagement/getAllTasksBryntumStruc`, {
        params: { project: '66300cee23006656a617f366' }
      })
  }

  updateGanttData() {
    // http://localhost:3000/projectmanagement/updateTaskPlannerBryntum?project=66103a7a4f913ba2c04aa115

  }
  projectID: string;
  getAllTasksTimeline(specificprojectid) {
    console.log("Before calling api", this.auth.getktoken())
    const token = this.auth.getktoken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}` // Assuming the token is a Bearer token, adjust as necessary
    });
    return this.http
      .get(environment.apiBaseUrl + "projectmanagement/getAllTasksBryntumStruc", {
        headers: headers,
        params: { project: specificprojectid }
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403 && error.error.auth === false && error.error.message === 'No token provided') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          else if (error.status === 500 && error.error.auth === false && error.error.message === 'Token Authentication Failed') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          return throwError(() => error); // Rethrow the error after handling
        })
      );
  }

  updateTaskPlanner(updatedGantt, specificprojectid) {
    const token = this.auth.getktoken();
    console.log("On updaaaatteeeeee", token)
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}` // Assuming the token is a Bearer token, adjust as necessary
    });
    return this.http
      .post<any>(environment.apiBaseUrl + "projectmanagement/updateTaskPlannerBryntum", updatedGantt, {
        headers: headers,
        params: { project: specificprojectid }
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403 && error.error.auth === false && error.error.message === 'No token provided') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          else if (error.status === 500 && error.error.auth === false && error.error.message === 'Token Authentication Failed') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          return throwError(() => error); // Rethrow the error after handling
        })
      );
  }

  getSpecificPhase(phaseId: string, versionId, specificprojectid) {
    console.log("---Specific phase-------");
    console.log("Before calling api", this.auth.getktoken())
    const token = this.auth.getktoken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}` // Assuming the token is a Bearer token, adjust as necessary
    });
    return this.http
      .get(
        environment.apiBaseUrl +
        `projectmanagement/getSubphaseTaskListBryntum/${phaseId}/${versionId}`,
        {
          headers: headers,
          params: { project: specificprojectid }
        }
      ).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403 && error.error.auth === false && error.error.message === 'No token provided') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          else if (error.status === 500 && error.error.auth === false && error.error.message === 'Token Authentication Failed') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          return throwError(() => error); // Rethrow the error after handling
        })
      );
  }

  updateTaskPlannerByPhase(updatedGantt, phaseId, specificprojectid) {
    const token = this.auth.getktoken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}` // Assuming the token is a Bearer token, adjust as necessary
    });
    return this.http
      .post<any>(environment.apiBaseUrl + `projectmanagement/updateTaskPlannerByPhaseBryntum/${phaseId}`, updatedGantt, {
        headers: headers,
        params: { project: specificprojectid }
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403 && error.error.auth === false && error.error.message === 'No token provided') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          else if (error.status === 500 && error.error.auth === false && error.error.message === 'Token Authentication Failed') {
            this.message.error('Session expired, please login again', { nzDuration: 0 }).messageId;
            setTimeout(() => {
              document.location.href = `${environment.oAuthUrl}/#/auth/login`;
            }, 5000);
          }
          return throwError(() => error); // Rethrow the error after handling
        })
      );
  }
  getMasterInfo(specificprojectid) {
    const token = this.auth.getktoken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}` // Assuming the token is a Bearer token, adjust as necessary
    });
    return this.http
      .get(environment.apiBaseUrl + "master-info/getMasterInfo", {
        headers: headers,
        params: { project: specificprojectid }
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error); // Rethrow the error after handling
        })
      )
  }
  getUserByID(specificprojectid) {
    const token = this.auth.getktoken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}` // Assuming the token is a Bearer token, adjust as necessary
    });
    return this.http
      .get(environment.apiBaseUrl + "users/getUserByID", {
        headers: headers,
        params: { project: specificprojectid }
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error); // Rethrow the error after handling
        })
      );
  }
}