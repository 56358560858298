import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlannerComponent } from './planner.component';
import { GanttComponent } from './gantt/gantt.component';
import { AuthGuard } from '../../core/guards/auth.guard';

const routes: Routes = [
    {
        path: 'timeline',
        component: PlannerComponent,
        children: [
            { path: '', redirectTo: 'gantt', pathMatch: 'full' }, // Default route for planner
            { path: '', component: GanttComponent },
            // Add more child routes here as needed
        ],
        canActivate: []
    },
    {
        path: 'timeline/:id/:versionid',
        component: PlannerComponent,
        children: [
            { path: '', redirectTo: 'gantt', pathMatch: 'full' }, // Default route for planner
            { path: '', component: GanttComponent },
            // Add more child routes here as needed
        ],
        canActivate: []
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PlannerRoutingModule { }
