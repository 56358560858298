import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GanttComponent } from './gantt.component';
import { BryntumGanttModule } from '@bryntum/gantt-angular';
import { AppNgZorroAntdModule } from '../../../app-ng-zorro-antd.module';
import { AppPrimeNGModule } from '../../../app-primeng.module';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';


@NgModule({
  declarations: [GanttComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppNgZorroAntdModule,
    AppPrimeNGModule,
    BryntumGanttModule,
    AbilityModule
  ],
  providers: [
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability }
  ],
  exports: [GanttComponent]
})
export class GanttModule { }
