export const environment = {
    name: "quality",
    production: true,
    apiBaseUrl: "https://quality.ktern.com/",
    apiUrlProfile: "https://quality.ktern.com/",
    oAuthUrl: "https://quality.ktern.com",
    oAuthClient: "fa12dd4a-c1bb-47c9-b0bd-0386b318f026",
    logoutUrl: "https://quality.ktern.com/#/auth/login",
    // amplitudeCode: "9a0dc5e12bad6737586ea94e2d0acc16",
    amplitudeCode: "3851b549d5e59a82e683194286ab1c49"
  };