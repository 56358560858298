import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DateHelper, DomHelper, Gantt, StringHelper } from '@bryntum/gantt';
import { BryntumGanttComponent, BryntumGanttProps } from '@bryntum/gantt-angular';
// import { NzDrawerService } from 'ng-zorro-antd/drawer';
import * as _ from 'underscore';
import moment from 'moment';
import './lib/GanttToolbar.js';
import './lib/StatusColumn.js';
import { GanttDataService } from "../../../core/services/gantt-data.service"
// import { TaskDetailsComponent } from "./../task-details/task-details.component";
import { TaskModel } from '@bryntum/gantt';
import { _data } from "../../../core/services/gantt-data";
import Task from './lib/Task';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, takeWhile } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http/index.js';
import { ConfirmationService } from 'primeng/api';
import { Ability, AbilityBuilder, RawRule } from '@casl/ability';
// import { ConfirmationService } from 'primeng/api/confirmationservice.js';

interface GanttFilterToolbox {
  searchQuery: string;
  selectedStatus: any[];
  selectedWorkstream: any[];
  selectedTasktype: any[];
  selectedRole: any[];
  selectedOverdue: boolean;
  selectedCritical: boolean;
  selectedOwner: any[];
  selectedPhase: any[];
  selectedWorkpackage: any[];
  selectedDateCombinationPlannedfrom: string;
  selectedPlannedfromDate: string;
  selectedDateCombinationPlannedto: string;
  selectedPlannedtoDate: string
}

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})

export class GanttComponent implements OnInit {
  dataSaveLoader: boolean = false;
  simulationData: any;
  columnsOption: string[] = [];
  ganttReady: boolean = false;
  columnsSelected: string[] = [];

  featuresOption: string[] = ["Draw dependencies", "Task labels", "Project lines", "Rollups", "Compare with baseline version"];
  featuresSelected: string[] = ["Draw dependencies", "Task labels", "Project lines"];

  //ToolBox related variables
  showWBS: boolean = true;
  expandLevel: string = "expandAll";
  durationScale: string = "A";

  showSuccessMessage: boolean = false;

  // Filter drawer related variables
  visibleFilterDrawer = false;

  openFilterDrawer(): void {
    this.visibleFilterDrawer = true;
  }

  closeFilterDrawer(): void {
    this.visibleFilterDrawer = false;
  }

  elemFullScreen: any;

  // @ViewChild('gantt') ganttComponent!: BryntumGanttComponent;
  @ViewChild(BryntumGanttComponent, { static: false }) ganttComponent!: BryntumGanttComponent;
  ganttInstance: Gantt | any;

  ganttFilterToolbox: GanttFilterToolbox = {
    searchQuery: "",
    selectedStatus: [],
    selectedWorkstream: [],
    selectedTasktype: [],
    selectedRole: [],
    selectedOverdue: false,
    selectedCritical: false,
    selectedOwner: [],
    selectedPhase: [],
    selectedWorkpackage: [],
    selectedDateCombinationPlannedfrom: "Equal to",
    selectedPlannedfromDate: "",
    selectedDateCombinationPlannedto: "Equal to",
    selectedPlannedtoDate: "",
  }
  demo: boolean = false;

  constructor(private ganttDataService: GanttDataService, private message: NzMessageService, private elementRef: ElementRef, private route: ActivatedRoute, private confirmationService: ConfirmationService, private ability: Ability,) {
  }

  // openTaskDrawer(taskDetails) {
  //     console.log("Task Details", taskDetails.name)
  //     const drawerRef = this.drawerService.create<TaskDetailsComponent, { value: string }, string>({
  //         nzTitle: taskDetails.name,
  //         // nzFooter: 'Footer',
  //         // nzExtra: 'Extra',
  //         nzContent: TaskDetailsComponent,
  //         nzWidth: 1000,
  //         nzContentParams: {
  //             value: taskDetails
  //         }
  //     });

  //     drawerRef.afterOpen.subscribe(() => {
  //         console.log('Drawer(Component) open');
  //     });

  //     drawerRef.afterClose.subscribe(data => {
  //         console.log("Drawer component closed", data);
  //     });
  // }

  displayRecord(record) {
    console.log("Clicked task", record)
    console.log("Am I accessible")
    // this.openTaskDrawer(record);
  }

  convertToBryntumGanttData(tasks) {
    console.log("Incoming tasks data", tasks)
    // Create a map to store tasks by their id
    const taskMap = new Map();

    // Initialize the root tasks array
    const rootTasks = [];

    // Iterate through each task
    tasks.forEach(task => {
      // Create a new task object for Bryntum Gantt
      let ganttTask = {
        id: task.id,
        name: task.text,
        percentDone: task.progress,
        duration: task.duration,
        startDate: task.start_date,
        endDate: task.end_date,
        expanded: task.open,
        children: [],
        // Add other necessary fields here
      };

      // Add the task to the map
      taskMap.set(task.id, ganttTask);

      // Check if the task has a parent
      if (task.parent) {
        // Get the parent task from the map
        const parentTask = taskMap.get(task.parent);

        // If the parent task exists, add the current task as a child
        if (parentTask) {
          parentTask.children.push(ganttTask);
        } else {
          // If the parent task doesn't exist yet, initialize an empty children array
          // and store it temporarily until the parent is processed
          taskMap.set(task.parent, { children: [ganttTask] });
        }
      } else {
        // If the task has no parent, it's a root task
        rootTasks.push(ganttTask);
      }
    });

    return rootTasks;
  }

  ganttTasksData: any;
  displayGantt: boolean = false;
  waveID: any;
  versionID: any;
  project: any;
  ngOnInit() {

    // console.log("This is Gantt config", this.ganttConfig);
    // console.log(_.pluck(this.ganttConfig.columns, "text"))
    // this.columnsOption = _.pluck(this.ganttConfig.columns, "text")
    // console.log("Column options", this.columnsOption)
    // this.columnsSelected = ['#', 'MS', 'Title', "Planned Start", "Planned End", "Status", "Predecessors", "Successors"];
    // this.defineGanttConfig();
    // console.log("Task type options", this.tasktypeOptions)
    // console.log("Status options", this.statusOptions)
    this.waveID = this.route.snapshot.paramMap.get('id');
    this.versionID = this.route.snapshot.paramMap.get('versionid');
    this.project = this.route.snapshot.queryParamMap.get('project');
    // this.project = this.route.snapshot.queryParamMap.get('project');
    console.log("waveID", this.waveID);
    console.log("versionID", this.versionID);
    console.log("projectID", this.project);
    const loadingIndicator = this.message.loading('Loading project plan please wait...', { nzDuration: 0 }).messageId;
    if (this.waveID === null && this.versionID === null) {
      console.log("COMPLETE DATA")
      this.ganttDataService.getAllTasksTimeline(this.project).subscribe((res: any) => {
        console.log("Response Data", res)
        this.simulationData = res;
        this.setDropdownOptions();
        this.calculateMaxTaskLevel(this.simulationData.tasks.rows);
        this.defineBryntumGanttConfig();
        this.selectedLevel = this.getMaxLevel();
        this.displayGantt = true;
        this.message.remove(loadingIndicator);
      }, (error: HttpErrorResponse) => {
        this.message.remove(loadingIndicator);
        this.message.error('Something went wrong', {
          nzDuration: 5000
        })
      })

    } else {
      console.log("WAVE SPECIFIC DATA")
      this.ganttDataService.getSpecificPhase(this.waveID, this.versionID, this.project).subscribe((res: any) => {
        console.log("Hardcoded motiva data", res)
        this.simulationData = res;
        this.setDropdownOptions();
        this.calculateMaxTaskLevel(this.simulationData.tasks.rows);
        this.defineBryntumGanttConfig();
        this.selectedLevel = this.getMaxLevel();
        this.displayGantt = true;
        this.message.remove(loadingIndicator);
      }, (error: HttpErrorResponse) => {
        this.message.remove(loadingIndicator);
        this.message.error('Something went wrong, please try again', {
          nzDuration: 5000
        })
      })
    }
    console.log("Status options", this.statusOptions);
    forkJoin({
      user: this.getUserByID(),
      masterInfo: this.getMasterInfo()
    }).subscribe({
      next: ({ user, masterInfo }) => {
       this.currentUser = user,
       this.masterInfoDetails = masterInfo,
       this.updateAbility(this.masterInfoDetails.accessLevels);
      },
      error: (error) => {
        // Handle any errors here
        console.error('Error:', error);
      }
    });
    // this.isSaveButtonDisabled();
  }
  masterInfoDetails
  currentUser
 
  getUserByID(): Observable<any> {
    return this.ganttDataService.getUserByID(this.project) // example API call
  }
  
  getMasterInfo(): Observable<any> {
    return this.ganttDataService.getMasterInfo(this.project); // example API call
  }

  allowUpdate: boolean = true;
  
  preparePlanner() {
    this.columnsOption = _.pluck(this.ganttConfig.columns, "text")
    this.columnsSelected = this.columnsOption;
    console.log("Converted Data", this.ganttTasksData)
    // this.ganttConfig.project.tasksData = this.ganttTasksData;
    this.refreshConfig()
    // this.ganttReady = true;
  }

  ngAfterViewInit() {
    this.elemFullScreen = this.elementRef.nativeElement.getElementsByClassName("full-screen-container");
    this.ganttInstance = this.ganttComponent.instance;
    this.setBarWidth();
    this.setRowHeight();
    this.setDependencyLinkLayout();
  }

  statusSelected: string[] = [];
  statusOptions: any[] = []

  phaseSelected: string[] = [];
  phaseOptions: any[] = [];

  workpackageSelected: string[] = []
  workpackageOptions: any[] = [];

  taskl1Selected: string[] = [];
  taskl1Options: any[] = [];

  ownerSelected: string[] = [];
  ownerOptions: any[] = [];

  tasktypeSelected: string[] = [];
  tasktypeOptions: any[] = []

  workstreamSelected: string[] = [];
  workstreamOptions: any[] = []

  roleSelected: string[] = [];
  roletypeOptions = []

  openDrawer: boolean = false;

  resourceOptions = []

  dateCombinationsPlannedfrom: string[] = ["Equal to", "Less than", "Greater than"];
  combinationSelectedPlannedfrom: string = "Equal to";

  dateCombinationsPlannedto: string[] = ["Equal to", "Less than", "Greater than"];
  combinationSelectedPlannedto: string = "Equal to";

  plannedFromSelected: any;
  plannedToSelected: any;

  showOnlyOverdue: boolean = false;
  showOnlyCritical: boolean = false;

  maxTaskLevel: number = 0;

  newStatusId: string;
  newTaskColor: string;

  setDropdownOptions() {
    this.statusOptions = this.simulationData.statusType;
    this.newStatusId = this.statusOptions.find(dt => dt.category === "New")._id;
    this.newTaskColor = this.statusOptions.find(dt => dt.category === "New").color;
    console.log("New status Id", this.newStatusId)
    this.phaseOptions = this.getTasksAtLevel_Legacy(this.simulationData.tasks.rows, 1);
    this.workpackageOptions = this.getTasksAtLevel_Legacy(this.simulationData.tasks.rows, 2);
    // this.taskl1Options = this.getTasksAtLevel_Legacy(this.simulationData.tasks.rows, 3);
    this.ownerOptions = this.simulationData.resources.rows;
    this.tasktypeOptions = this.simulationData.taskType;
    this.workstreamOptions = this.simulationData.workstreamtype;
    this.roletypeOptions = this.simulationData.roletypeoptions;
    this.resourceOptions = this.simulationData.resources.rows
  }

  calculateMaxTaskLevel(tasks: any[], currentLevel: number = 1) {
    tasks.forEach(task => {
      if (currentLevel > this.maxTaskLevel) {
        this.maxTaskLevel = currentLevel;
      }
      if (task.children && task.children.length > 0) {
        this.calculateMaxTaskLevel(task.children, currentLevel + 1);
      }
    });
  }

  selectedLevel: number = 9;
  getLevels(): { value: number, label: string }[] {
    return Array.from({ length: this.maxTaskLevel }, (_, i) => ({
      value: i + 1,
      label: `Expanded upto L${i + 1}`
    }));
  }


  getTasksAtLevel(level: number): any[] {
    const result: { id: string, name: string }[] = [];
    const traverseTasks = (tasks: any[], currentLevel: number) => {
      tasks.forEach(task => {
        if (currentLevel === level) {
          result.push({ id: task.id, name: task.name });
        }
        if (task.children && task.children.length > 0) {
          traverseTasks(task.children, currentLevel + 1);
        }
      });
    };

    traverseTasks(this.simulationData.tasks.rows, 1);
    return result;
  }

  // Function to handle level selection from dropdown
  onLevelSelect(level: number, operationType: string) {
    if (operationType === 'expand') {
      // Expand tasks at and before the selected level
      // for (let i = 1; i <= level; i++) {
      //   const tasksAtLevel = this.getTasksAtLevel(i);
      //   tasksAtLevel.forEach(task => this.ganttInstance.expand(task.id));
      // }
      this.ganttInstance.features.tree.expandToLevel(level - 1, true);
    } else if (operationType === 'collapse') {
      // Collapse tasks at and after the selected level
      // const maxLevel = this.getMaxLevel();
      // for (let i = level; i <= maxLevel; i++) {
      //   const tasksAtLevel = this.getTasksAtLevel(i);
      //   tasksAtLevel.forEach(task => this.ganttInstance.collapse(task.id));
      // }
    }
  }

  // Function to get the maximum level present in the data
  getMaxLevel(): number {
    let maxLevel = 1;

    const traverseTasks = (tasks: any[], currentLevel: number) => {
      tasks.forEach(task => {
        if (currentLevel > maxLevel) {
          maxLevel = currentLevel;
        }
        if (task.children && task.children.length > 0) {
          traverseTasks(task.children, currentLevel + 1);
        }
      });
    };

    traverseTasks(this.simulationData.tasks.rows, 1);
    return maxLevel;
  }


  getTasksAtLevel_Legacy(tasks: any[], n: number, currentLevel: number = 1): any[] {
    // Base case: If current level equals the desired level, return the tasks at this level
    if (currentLevel === n) {
      return tasks.map(task => ({ id: task.id, name: task.name, wbs: task.wbs }));
    }

    // Initialize an array to hold tasks found at the desired level
    let tasksAtLevel: Task[] = [];

    // Traverse the current level tasks
    for (const task of tasks) {
      // If the task has children, continue traversing
      if (task.children) {
        tasksAtLevel = tasksAtLevel.concat(this.getTasksAtLevel_Legacy(task.children, n, currentLevel + 1));
      }
    }

    return tasksAtLevel;
  }

  isTodayGreaterThan(dateString) {
    // Parse the input date string
    const givenDate = new Date(dateString);

    // Get today's date
    const today = new Date();

    // Set the time portion to 00:00:00 to compare only the dates
    today.setHours(0, 0, 0, 0);
    givenDate.setHours(0, 0, 0, 0);

    // Compare the dates
    return today > givenDate;
  }

  handleTaskClick(record: any) {
    // Access the task data
    const taskData = record._data;

    // Log or process the task data as needed
    console.log("Task clicked:", taskData);

    // Implement further logic, such as opening a modal, navigating, etc.
    // this.displayTaskDetails(taskData); // Example function to display task details
  }

  getStatusForBryntum(status_id) {
    if (status_id != undefined) {
      return this.statusOptions.find(dt => dt._id === status_id);
    }
  }

  ganttConfig: BryntumGanttProps = {
    dependencyIdField: 'wbsCode',
    // dependencyIdField: 'sequenceNumber',
    selectionMode: {
      cell: true,
      dragSelect: true,
      rowNumber: true
    },

    preserveScrollOnDatasetChange: true,
    displaySchedulingIssueResolutionPopup: false,

    showTaskColorPickers: false,
    showTooltip: true,

    taskMenuFeature: {
      items: {
        cut: false,
        editTask: false,
        // add: {
        //   menu: {
        //     subtask: false,
        //     milestone: false,

        //   }
        // },
        add: false,
        addTaskAbove: {
          text: 'Add Task above',
          icon: 'b-icon-up',
          onItem: ({ taskRecord }: any) => {
            console.log(taskRecord.parent, taskRecord.child, taskRecord.successor, taskRecord.predecessor)
            taskRecord.parent.insertChild({
              wbs: '1.1.1', name: 'NEW TASK ABOVE', duration: 1, taskstatus: this.newStatusId, eventColor: this.newTaskColor, manuallyScheduled: true
            }, taskRecord);
            // run propagation to calculate new task fields
            // this.ganttInstance.project.propagate();
          }
        },
        addTaskBelow: {
          text: 'Add Task below',
          icon: 'b-icon-up',
          onItem: ({ taskRecord }: any) => {
            console.log(taskRecord.parent, taskRecord.child, taskRecord.successor, taskRecord.predecessor)
            taskRecord.parent.insertChild({
              wbs: '1.1.1', name: 'NEW TASK BELOW', duration: 1, taskstatus: this.newStatusId, eventColor: this.newTaskColor, manuallyScheduled: true
            }, taskRecord.nextSibling);
            // run propagation to calculate new task fields
            // this.ganttInstance.project.propagate();
          }
        },
        delete: true,
        convertToMilestone: false,
        linkTasks: false,
        unlinkTasks: false
      }
    },

    cellEditFeature: {
      addNewAtEnd: false
    },

    taskTooltipFeature: {
      disabled: true
      // template: ({ taskRecord }) => `${taskRecord.name}`,
      // // Tooltip configs can be used here
      // align: 'l-r' // Align left to right
    },

    project: {
      skipNonWorkingTimeWhenSchedulingManually: true,
      skipNonWorkingTimeInDurationWhenSchedulingManually: true,
      autoSetConstraints: true,
      hoursPerDay: 23.999999999,
      daysPerWeek: 5,
      daysPerMonth: 20,
      calendar: 'general',
      calendarsData: [
        {
          id: "general",
          name: "General",
          intervals: [
            {
              recurrentStartDate: "on Sat at 0:00",
              recurrentEndDate: "on Mon at 0:00",
              isWorking: false
            }
          ]
        }
      ],
      // Let the Project know we want to use our own Task model with custom fields / methods
      taskModelClass: Task,
      // transport: {
      //   load: {
      //     url: 'assets/data/launch-motiva.json'
      //   }
      // },
      autoLoad: true,



      // assignmentStore: {
      //   data: [],
      //   useRawData: true,
      // },

      // resourceStore: {
      //   data: [],
      //   useRawData: true,
      // },

      // taskStore: {
      //   data: [],
      //   useRawData: true,
      // },

      // dependencyStore: {
      //   data: [],
      //   useRawData: true,
      // },

      // resourceTimeRangeStore: {
      //   useRawData: true,
      // },

      // The State TrackingManager, which the UndoRedo widget in the toolbar uses
      stm: {
        // NOTE, that this option does not enable the STM itself, this is done by the `undoredo` widget, defined in the toolbar
        // If you don't use `undoredo` widget in your app, you need to enable STM manually: `stm.enable()`,
        // otherwise, it won't be tracking changes in the data
        // It's usually best to enable STM after the initial data loading is completed.
        autoRecord: true
      },

      // This config enables response validation and dumping of found errors to the browser console.
      // It's meant to be used as a development stage helper only, so please set it to false for production systems.
      validateResponse: true,
      delayCalculation: false,
      enableProgressNotifications: true,
      useRawData: true
    },

    startDate: '2020-01-12',
    endDate: '2040-03-24',
    columns: [
      { type: 'sequence', text: '#', width: 50, align: 'center' },
      {
        width: 60,
        type: 'manuallyscheduled',
        text: "MS",
        align: 'center',
        // showCheckAll: true,
        sortable: false,
        filterable: false,
        afterRenderCell({ record, widgets }: any) {
          // Hide checkboxes in certain rows
          const wbsLevel = record.wbs.split('.').length;

          // Hide the checkbox if WBS level is 1 or 2
          if (wbsLevel <= 2) {
            widgets[0].hidden = true;
          } else {
            widgets[0].hidden = false;
          }

        }
      },
      {
        type: 'name',
        width: 450,
        text: "Title",
        showWbs: true,
        hidden: false,
        align: 'center',
        sortable: false,
        filterable: false,
      },
      {
        type: 'predecessor',
        text: "Predecessors",
        width: 112,
        align: 'center',
        sortable: false,
        filterable: false,
      },
      // {
      //   type: 'predecessor',
      //   text: "Predecessors",
      //   width: 112,
      //   align: 'center',
      //   sortable: false,
      //   filterable: false,
      //   // invalidAction: 'allow'
      // },
      // {
      //   type: 'totalslack',
      //   // text: "Predecessors",
      //   // width: 112,
      //   // align: 'center',
      //   // sortable: false,
      //   // filterable: false,
      //   // invalidAction: 'allow'
      // },
      {
        type: 'successor',
        text: "Successors",
        width: 112,
        align: 'center',
        sortable: false,
        filterable: false,
      },
      {
        text: 'Status',
        field: 'taskstatus',
        width: 120,
        type: 'template',
        align: 'center',
        sortable: false,
        filterable: false,
        editor: {
          type: 'combo',
          items: this.statusOptions,
          displayField: 'status',
          valueField: '_id'
        },
        template: ({ value = '' }) => {
          const status = this.getStatusForBryntum(value);
          if (status != undefined) {
            return StringHelper.xss`
          <div class="b-status b-status-${status.category.toLowerCase()}">${status.status}</div>`
          }
          return StringHelper.xss`
          <div class="b-status b-status-na">NA</div>`
        },
      },
      {
        type: 'startdate',
        text: "Planned Start",
        align: 'center',
        width: 100,
        sortable: false,
        filterable: false,
      },
      {
        type: 'enddate',
        text: "Planned End",
        align: 'center',
        width: 100,
        sortable: false,
        filterable: false,
        editor: false
      },

      { type: 'duration', text: "Duration", align: 'center', sortable: false, filterable: false },
      { type: 'baselinestartdate', hidden: true, align: 'center', },
      { type: 'baselineenddate', hidden: true },
      {
        type: 'baselinedurationvariance',
        align: 'center',
        width: 120,
        sortable: false,
        filterable: false,
        editor: false,
        renderer: (cellProps: { record: any, cellElement: any, value: any }) => {
          // Reset any existing styles or classes
          let value = cellProps.value
          if (value && value._magnitude) {
            const durationVariance = Math.floor(value._magnitude)
            if (durationVariance > 0) {
              cellProps.cellElement.style.color = "red";
            } else if (durationVariance < 0) {
              cellProps.cellElement.style.color = "green";
            }
            return durationVariance.toString();
          } else {
            cellProps.cellElement.style.color = "black";
            return "0";
          }
        }
      },
      { type: 'earlystartdate', hidden: true, align: 'center' },
      { type: 'earlyenddate', hidden: true, align: 'center' },
      { type: 'latestartdate', hidden: true, align: 'center' },
      { type: 'lateenddate', hidden: true, align: 'center' },
      {
        type: 'totalslack',
        // text: "Predecessors",
        width: 112,
        align: 'center',
        sortable: false,
        filterable: false,
      },
      // { type: 'resourceassignment', width: 120, showAvatars: true, text: "Owners" },
      {
        text: 'Workstream Type',
        field: 'workstreamtype',
        align: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        editor: {
          type: 'combo',
          items: this.workstreamOptions,
          displayField: 'name',
          valueField: 'id'
        },
        renderer: ({ value }: { value: string }) => {
          const status = this.workstreamOptions.find(option => option._id === value);
          return status ? status.name : value;
        }
      },
      {
        text: 'Owner',
        field: 'assignedTo',
        width: 120,
        align: 'center',
        sortable: false,
        filterable: false,
        editor: {
          type: 'combo',
          items: this.resourceOptions,
          displayField: 'name',
          valueField: 'id'
        },
        renderer: ({ value, cellElement }) => {
          const owner = this.resourceOptions.find(option => option.id === value);
          return owner ? owner.name : "NA";
        }
      },
      {
        text: 'Task Type',
        field: 'tasktype',
        width: 120,
        align: 'center',
        sortable: false,
        filterable: false,
        editor: {
          type: 'combo',
          items: this.tasktypeOptions,
          displayField: 'name',
          valueField: 'id'
        },
        renderer: ({ value }: { value: string }) => {
          const status = this.tasktypeOptions.find(option => option._id === value);
          return status ? status.name : value;
        },
      },
      {
        text: 'Role Type',
        field: 'roletype',
        align: 'center',
        width: 120,
        sortable: false,
        filterable: false,
        editor: {
          type: 'combo',
          items: this.roletypeOptions,
          displayField: 'name',
          valueField: 'id'
        },
        renderer: ({ value }: { value: string }) => {
          const status = this.roletypeOptions.find(option => option._id === value);
          return status ? status.name : value;
        }
      },
      {
        type: 'number',
        field: 'plannedmilestonepercentage', // Key from the backend
        text: 'Planned Milestone %',
        width: 150,
        sortable: false,
        filterable: false,
        editor: false
      },
      {
        type: 'number',
        field: 'actualmilestonepercentage', // Key from the backend
        text: 'Actual Milestone %',
        width: 150,
        sortable: false,
        filterable: false,
        editor: false
      }
    ],

    listeners: {
      cellClick: ({ grid, record, column, cellElement, target, event }: any) => {
        console.log("Clicked on cell", grid, record, column, cellElement, target, event)
      }
    },

    // subGridConfigs: {
    //   locked: {
    //     flex: 3
    //   },
    //   normal: {
    //     flex: 4
    //   }
    // },

    subGridConfigs: {
      locked: { width: 1000 }
    },

    columnLines: false,

    rollupsFeature: {
      disabled: true
    },

    baselinesFeature: {
      disabled: true,
      renderer: ({ baselineRecord, taskRecord, renderData }: any) => {
        // console.log("Baseline record", baselineRecord)
        if (baselineRecord.isScheduled && baselineRecord.endDate.getTime() + 24 * 3600 * 1000 < taskRecord.endDate.getTime()) {
          renderData.className['b-baseline-behind'] = 1;
        }
        else if (taskRecord.endDate < baselineRecord.endDate) {
          renderData.className['b-baseline-ahead'] = 1;
        }
        else {
          renderData.className['b-baseline-on-time'] = 1;
        }
      },
    },

    progressLineFeature: {
      disabled: true,
      statusDate: new Date(2019, 0, 25)
    },

    filterFeature: true,

    dependencyEditFeature: true,

    timeRangesFeature: {
      showCurrentTimeLine: true
    },

    labelsFeature: {
      left: {
        field: 'name',
        editor: {
          type: 'textfield'
        }
      }
    },

    tbar: {
      // @ts-ignore This is an application custom widget
      type: 'gantttoolbar'
    }
  }

  defineBryntumGanttConfig() {
    this.ganttConfig = {
      dependencyIdField: 'wbsCode',
      // dependencyIdField: 'sequenceNumber',
      selectionMode: {
        cell: true,
        dragSelect: true,
        rowNumber: true
      },

      preserveScrollOnDatasetChange: true,
      displaySchedulingIssueResolutionPopup: false,

      showTaskColorPickers: false,
      showTooltip: false,

      taskMenuFeature: {
        items: {
          cut: false,
          editTask: false,
          // add: {
          //   menu: {
          //     subtask: false,
          //     milestone: false,

          //   }
          // },
          add: false,
          addTaskAbove: {
            text: 'Add Task above',
            icon: 'b-icon-up',
            onItem: ({ taskRecord }: any) => {
              console.log(taskRecord.parent, taskRecord.child, taskRecord.successor, taskRecord.predecessor)
              taskRecord.parent.insertChild({
                wbs: '1.1.1', name: 'NEW TASK ABOVE', duration: 1, taskstatus: this.newStatusId, eventColor: this.newTaskColor, manuallyScheduled: true
              }, taskRecord);
              // run propagation to calculate new task fields
              // this.ganttInstance.project.propagate();
            }
          },
          addTaskBelow: {
            text: 'Add Task below',
            icon: 'b-icon-down',
            onItem: ({ taskRecord }: any) => {
              console.log(taskRecord.parent, taskRecord.child, taskRecord.successor, taskRecord.predecessor)
              taskRecord.parent.insertChild({
                wbs: '1.1.1', name: 'NEW TASK BELOW', duration: 1, taskstatus: this.newStatusId, eventColor: this.newTaskColor, manuallyScheduled: true
              }, taskRecord.nextSibling);
              // run propagation to calculate new task fields
              // this.ganttInstance.project.propagate();
            }
          },
          delete: true,
          convertToMilestone: false,
          linkTasks: false,
          unlinkTasks: false
        }
      },

      cellEditFeature: {
        addNewAtEnd: false
      },

      taskTooltipFeature: {
        disabled: true
        // template: ({ taskRecord }) => `${taskRecord.name}`,
        // // Tooltip configs can be used here
        // align: 'l-r' // Align left to right
      },

      project: {
        skipNonWorkingTimeWhenSchedulingManually: true,
        skipNonWorkingTimeInDurationWhenSchedulingManually: true,
        autoSetConstraints: true,
        hoursPerDay: 23.999999999,
        daysPerWeek: 5,
        daysPerMonth: 20,
        calendar: 'general',
        calendarsData: [
          {
            id: "general",
            name: "General",
            intervals: [
              {
                recurrentStartDate: "on Sat at 0:00",
                recurrentEndDate: "on Mon at 0:00",
                isWorking: false
              }
            ]
          }
        ],
        // Let the Project know we want to use our own Task model with custom fields / methods
        // transport: {
        //   load: {
        //     url: 'assets/data/launch-motiva.json'
        //   }
        // },
        autoLoad: true,

        assignmentStore: {
          data: this.simulationData.assignments.rows,
          useRawData: true,
        },

        resourceStore: {
          data: this.simulationData.resources.rows,
          useRawData: true,
        },

        taskStore: {
          data: this.simulationData.tasks.rows,
          useRawData: true,
        },

        dependencyStore: {
          data: this.simulationData.dependencies.rows,
          useRawData: true,
        },

        timeRangeStore: {
          data: [
            {
              "id": 1,
              "name": moment().format('Do MMM YY'),
              "startDate": moment().format('YYYY-MM-DD'),
              "duration": 0,
              "durationUnit": "d",
              "cls": "b-fa b-fa-calendar-day"
            }
          ]
        },

        // The State TrackingManager, which the UndoRedo widget in the toolbar uses
        stm: {
          // NOTE, that this option does not enable the STM itself, this is done by the `undoredo` widget, defined in the toolbar
          // If you don't use `undoredo` widget in your app, you need to enable STM manually: `stm.enable()`,
          // otherwise, it won't be tracking changes in the data
          // It's usually best to enable STM after the initial data loading is completed.
          autoRecord: true
        },

        // This config enables response validation and dumping of found errors to the browser console.
        // It's meant to be used as a development stage helper only, so please set it to false for production systems.
        validateResponse: true,
        delayCalculation: false,
        enableProgressNotifications: true,
        useRawData: true
      },

      // startDate: '2019-01-12',
      // endDate: '2019-03-24',
      startDate: this.simulationData.project.startDate,
      endDate: this.simulationData.project.endDate,
      columns: [
        { type: 'sequence', text: '#', width: 50, align: 'center' },
        {
          width: 60,
          type: 'manuallyscheduled',
          text: "MS",
          align: 'center',
          // showCheckAll: true,
          sortable: false,
          filterable: false,
          afterRenderCell({ record, widgets }: any) {
            // Hide checkboxes in certain rows
            const wbsLevel = record.wbs.split('.').length;

            // Hide the checkbox if WBS level is 1 or 2
            if (wbsLevel <= 2) {
              widgets[0].hidden = true;
            } else {
              widgets[0].hidden = false;
            }

          }
        },
        {
          type: 'name',
          width: 450,
          text: "Title",
          showWbs: true,
          hidden: false,
          align: 'center',
          filterable: false,
          sortable: false
        },
        {
          type: 'predecessor',
          text: "Predecessors",
          width: 112,
          align: 'center',
          sortable: false,
          filterable: false,
        },
        // {
        //   type: 'totalslack',
        //   // text: "Predecessors",
        //   width: 112,
        //   align: 'center',
        //   sortable: false,
        //   filterable: false,
        //   invalidAction: 'allow'
        // },
        // { type: 'earlystartdate' },
        // { type: 'earlyenddate' },
        // { type: 'latestartdate' },
        // { type: 'lateenddate' },
        {
          type: 'successor',
          text: "Successors",
          width: 112,
          align: 'center',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Status',
          field: 'taskstatus',
          width: 120,
          type: 'template',
          align: 'center',
          sortable: false,
          filterable: false,
          editor: {
            type: 'combo',
            items: this.statusOptions,
            displayField: 'status',
            valueField: '_id'
          },
          template: ({ value = '' }) => {
            const status = this.getStatusForBryntum(value);
            if (status != undefined) {
              return StringHelper.xss`
            <div class="b-status b-status-${status.category.toLowerCase()}">${status.status}</div>`
            }
            return StringHelper.xss`
            <div class="b-status b-status-na">NA</div>`
          }
        },
        {
          type: 'startdate',
          text: "Planned Start",
          align: 'center',
          width: 100,
          sortable: false,
          filterable: false,
        },
        {
          type: 'enddate',
          text: "Planned End",
          align: 'center',
          width: 100,
          sortable: false,
          filterable: false,
          editor: false,  // Disable editor for all rows (can override later)
        },
        {
          type: 'baselinestartdate',
          align: 'center',
          width: 100,
          sortable: false,
          filterable: false,
          editor: false,
          hidden: true
        },
        {
          type: 'baselineenddate',
          align: 'center',
          width: 100,
          sortable: false,
          filterable: false,
          editor: false,
          hidden: true
        },
        {
          type: 'duration', text: "Duration", align: 'center', sortable: false, filterable: false
        },

        { type: 'earlystartdate', hidden: true, align: 'center', },
        { type: 'earlyenddate', hidden: true, align: 'center' },
        { type: 'latestartdate', hidden: true, align: 'center' },
        { type: 'lateenddate', hidden: true, align: 'center' },

        {
          type: 'baselinedurationvariance',
          align: 'center',
          width: 120,
          sortable: false,
          filterable: false,
          editor: false,
          renderer: (cellProps: { record: any, cellElement: any, value: any }) => {
            // Reset any existing styles or classes
            let value = cellProps.value
            if (value && value._magnitude) {
              const durationVariance = Math.floor(value._magnitude)
              if (durationVariance > 0) {
                cellProps.cellElement.style.color = "red";
              } else if (durationVariance < 0) {
                cellProps.cellElement.style.color = "green";
              }
              return durationVariance.toString();
            } else {
              cellProps.cellElement.style.color = "black";
              return "0";
            }
          }
        },
        {
          type: 'totalslack',
          // text: "Predecessors",
          width: 112,
          align: 'center',
          sortable: false,
          filterable: false,
        },
        // { type: 'resourceassignment', width: 120, showAvatars: true, text: "Owners" },
        {
          text: 'Workstream Type',
          field: 'workstreamtype',
          align: 'center',
          width: 160,
          sortable: false,
          filterable: false,
          editor: {
            type: 'combo',
            items: this.workstreamOptions,
            displayField: 'name',
            valueField: '_id'
          },
          renderer: ({ value }: { value: string }) => {
            const status = this.workstreamOptions.find(option => option._id === value);
            return status ? status.name : value;
          }
        },
        {
          text: 'Owner',
          field: 'assignedTo',
          width: 120,
          align: 'center',
          sortable: false,
          filterable: false,
          editor: {
            type: 'combo',
            items: this.resourceOptions,
            displayField: 'name',
            valueField: 'id'
          },
          renderer: ({ value, cellElement }) => {
            const owner = this.resourceOptions.find(option => option.id === value);
            return owner ? owner.name : "NA";
          }
        },
        {
          text: 'Task Type',
          field: 'tasktype',
          width: 120,
          align: 'center',
          sortable: false,
          filterable: false,
          editor: {
            type: 'combo',
            items: this.tasktypeOptions,
            displayField: 'name',
            valueField: '_id'
          },
          renderer: ({ value }: { value: string }) => {
            const status = this.tasktypeOptions.find(option => option._id === value);
            return status ? status.name : value;
          },
        },
        {
          text: 'Role Type',
          field: 'roletype',
          align: 'center',
          width: 120,
          sortable: false,
          filterable: false,
          editor: {
            type: 'combo',
            items: this.roletypeOptions,
            displayField: 'name',
            valueField: '_id'
          },
          renderer: ({ value }: { value: string }) => {
            const status = this.roletypeOptions.find(option => option._id === value);
            return status ? status.name : value;
          }
        },
        {
          type: 'number',
          field: 'plannedmilestonepercentage', // Key from the backend
          text: 'Planned Milestone %',
          width: 150,
          sortable: false,
          filterable: false,
          editor: false
        },
        {
          type: 'number',
          field: 'actualmilestonepercentage', // Key from the backend
          text: 'Actual Milestone %',
          width: 150,
          sortable: false,
          filterable: false,
          editor: false
        }
      ],

      listeners: {
        cellClick: ({ grid, record, column, cellElement, target, event }: any) => {
          console.log("Clicked on cell", grid, record, column, cellElement, target, event)
        }
      },

      subGridConfigs: {
        locked: { width: 1000 }
      },

      columnLines: false,

      rollupsFeature: {
        disabled: true
      },

      dependenciesFeature: {
        disabled: false,
        radius: 50,
        clickWidth: 50
      },

      dependencyEditFeature: {
        disabled: false,
      },

      baselinesFeature: {
        disabled: true
      },

      progressLineFeature: {
        disabled: true,
        statusDate: new Date(2019, 0, 25)
      },

      filterFeature: true,


      timeRangesFeature: {
        showCurrentTimeLine: false,
      },

      labelsFeature: {
        left: {
          field: 'name',
          editor: {
            type: 'textfield'
          }
        }
      },

      tbar: {
        // @ts-ignore This is an application custom widget
        type: 'gantttoolbar'
      }
    }
  }

  setColumnVisibility(): void {
    const columnsToShow = this.columnsSelected;
    console.log(this.columnsSelected)

    // this.ganttConfig.columns.forEach(column => {
    //   if (columnsToShow.includes(column.text)) {
    //     column.hidden = false;
    //   } else {
    //     column.hidden = true;
    //   }
    // });

    console.log("Display only Column", this.ganttConfig.columns)
    this.refreshConfig();
  }

  refreshConfig() {
    const ganttConfig = JSON.parse(JSON.stringify(this.ganttConfig));
    this.ganttConfig = ganttConfig;
  }

  onPdfExport(): void {
    this.ganttInstance.features.pdfExport.showExportDialog();
  }

  disableDependencyTooltip() {
    this.ganttInstance.features.dependencies.showTooltip = false;
  }

  setPlannerFeatures() {
    console.log("Features selected", this.featuresSelected)

    // //Show WBS
    // if (this.featuresSelected.includes("Show WBS")) {
    //   this.ganttInstance.showWBS = true;
    // } else { this.ganttInstance.features.showWBSToggle() }

    //"Compare with baseline version"
    if (this.featuresSelected.includes("Compare with baseline version")) {
      this.ganttInstance.features.baselines.disabled = false;
      this.ganttInstance.barMargin = 2;
      this.ganttInstance.rowHeight = 28;
    } else {
      this.ganttInstance.features.baselines.disabled = true;
      this.ganttInstance.barMargin = 18;
      this.ganttInstance.rowHeight = 24;
    }

    //Draw dependencies
    if (this.featuresSelected.includes("Draw dependencies")) {
      this.ganttInstance.features.dependencies.disabled = false;
    } else { this.ganttInstance.features.baselines.disabled = true; }

    //Task labels
    if (this.featuresSelected.includes("Task labels")) {
      this.ganttInstance.features.labels.disabled = false;
    } else { this.ganttInstance.features.labels.disabled = true; }

    //Project lines
    if (this.featuresSelected.includes("Project lines")) {
      this.ganttInstance.features.projectLines.disabled = false;
    } else { this.ganttInstance.features.projectLines.disabled = true; }

    //Highlight non-working time
    if (this.featuresSelected.includes("Highlight non-working time")) {
      this.ganttInstance.features.nonWorkingTime.disabled = false;
    } else { this.ganttInstance.features.nonWorkingTime.disabled = true; }

    //Rollups
    if (this.featuresSelected.includes("Rollups")) {
      this.ganttInstance.features.rollups.disabled = false;
    } else { this.ganttInstance.features.rollups.disabled = true; }
  }

  // customTaskTooltip() {
  //   console.log("Custom task tooltip")
  //   this.ganttInstance.features.taskToolTip = {
  //     textContent: true,
  //     template({ taskRecord }) {
  //       console.log("Are you working")
  //       const me = this;
  //       return `<div class="field"><label>Task</label><span>${StringHelper.encodeHtml(taskRecord.name)}</span></div>
  //             `;
  //     }
  //   }
  // }

  setRowHeight() {
    this.ganttInstance.rowHeight = 24;
  }

  setDependencyLinkLayout() {
    this.ganttInstance.features.dependencies.radius = 30;
    this.ganttInstance.features.dependencies.clickWidth = 40;
  }

  setBarWidth() {
    this.ganttInstance.barMargin = 18;
  }

  expandCollapse(event) {
    console.log("Expand Collapse", event)
    if (event === "expandAll") {
      this.ganttInstance.expandAll();
    } else if (event === "collapseAll") {
      this.ganttInstance.collapseAll();
    }
  }

  expandSpecific() {
    console.log("Expand specific task")
    this.ganttInstance.expand("3187d8e8d6beb0d53071bb9f");
  }

  expandToLevel() {
    // this.ganttInstance.
    this.ganttInstance.features.tree.expandToLevel(3, true);
  }

  toggleWbsDisplay(event) {
    console.log("Show wbs")
    this.ganttInstance.columns.get('name').showWbs = event;
  }

  zoomInView() {
    console.log("Zoom In")
    this.ganttInstance.zoomIn();
  }

  zoomOutView() {
    console.log("Zoom In")
    this.ganttInstance.zoomOut();
  }

  zoomToFit() {
    this.ganttInstance.zoomToFit();
  }

  shiftLeft() {
    this.ganttInstance.shiftPrevious();
  }

  shiftRight() {
    this.ganttInstance.shiftNext();
  }

  editMode() {
    console.log("Edit Mode Active")
    this.ganttInstance.readOnly = false;
  }

  confirm(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // Logic for when the user accepts
        console.log('Accepted');
        this.saveGanttData();
      },
      reject: () => {
        // Logic for when the user rejects
        console.log('Rejected');
      }
    });
  }

  saveGanttData() {
    // const tasks = gantt.project.taskStore.getRange().map(task => task.data);
    // const dependencies = gantt.project.dependencyStore.getRange().map(dep => dep.data);
    // console.log("Newwww way", this.ganttInstance.project.inlineData.tasks)
    // console.log("The OG data", tasks1)

    const dd = this.ganttInstance.project.inlineData
    console.log("Newww way", dd)

    // Remove the current view
    this.ganttInstance.taskStore.clearFilters();
    this.ganttInstance.expandAll();
    const tasks = this.ganttInstance.project.taskStore.records.map((task) => {
      // console.log("Inspection", task.startDate, moment(task.startDate).format('YYYY-MM-DD'))
      return {
        id: task.id,
        wbs: task.wbsValue._value,
        name: task.name,
        percentDone: task.percentDone,
        startDate: moment(task.startDate).format('YYYY-MM-DD'),
        endDate: moment(task.endDate).format('YYYY-MM-DD'),
        manuallyScheduled: task.manuallyScheduled,
        isOverDue: task.isOverDue,
        duration: task.duration,
        fullStatus: task.fullStatus,
        taskstatus: task.taskstatus,
        assignedTo: task.assignedTo,
        tasktype: task.tasktype,
        workstreamtype: task.workstreamtype,
        roletype: task.roletype,
        totalSlack: task.totalSlack,
        earlyStartDate: moment(task.earlyStartDate).format('YYYY-MM-DD'),
        earlyEndDate: moment(task.earlyEndDate).format('YYYY-MM-DD'),
        lateStartDate: moment(task.lateStartDate).format('YYYY-MM-DD'),
        lateEndDate: moment(task.lateEndDate).format('YYYY-MM-DD'),
      }
    });
    const dependencies = JSON.parse(this.ganttInstance.dependencyStore.json);
    const ganttData = {
      tasks: tasks,
      links: dependencies,
      svtrigger: false
    };
    console.log("Gantt tasks", tasks)
    console.log("Gantt dependencies", dependencies)
    console.log("Gantt payload", ganttData)

    // Restore the current view
    console.log("Get selected level", this.selectedLevel)
    this.filterBryntumGantt(this.ganttFilterToolbox)
    this.onLevelSelect(this.selectedLevel, 'expand');
    this.appliedFiltersCount = this.countAppliedFilters(this.ganttFilterToolbox)

    // Now call the API
    if (this.waveID === null && this.versionID === null) {
      this.dataSaveLoader = true;
      this.ganttDataService
        .updateTaskPlanner(ganttData, this.project)
        .subscribe(
          (res) => {
            this.demo = false
            this.dataSaveLoader = false;
            console.log("Reply after updating simulation", res);
            // this.message.create('success', 'Changes saved successfully');
            this.onSaveSuccess();
          },
          (err) => {
            this.demo = false;
            this.dataSaveLoader = false;
            console.error("Oops! Some unknown error occurred");
            this.message.create('error', 'Something went wrong, please try again');
          }
        );
    }
    else {
      this.dataSaveLoader = true;
      this.ganttDataService
        .updateTaskPlannerByPhase(ganttData, this.waveID, this.project)
        .subscribe(
          (res) => {
            this.dataSaveLoader = false;
            console.log("Reply after updating simulation", res);
            this.message.create('success', 'Changes saved successfully');
            this.onSaveSuccess();
          },
          (err) => {
            this.dataSaveLoader = false;
            console.error("Oops! Some unknown error occurred");
            this.message.create('error', 'Something went wrong, please try again');
          }
        );
    }
  }

  criticalPath: boolean = false;
  checked: boolean = false;
  showCriticalPath() {
    this.checked = !this.checked;
    console.log("Somethingggg", this.checked)
    this.criticalPath = this.checked;
    if (this.criticalPath) {
      console.log("Show critical path")
      this.ganttInstance.features.criticalPaths.disabled = false;
    } else {
      console.log("Remove critical path")
      this.ganttInstance.features.criticalPaths.disabled = true;
    }
  }

  searchTerm: string = "";
  appliedFiltersCount: number = 0;

  formulateFilterToolbox(filterValue, filterType) {
    // console.log(filterType)
    if (filterType === "searchQuery") {
      this.ganttFilterToolbox[filterType] = this.searchTerm;
    } else {
      this.ganttFilterToolbox[filterType] = filterValue;
    }

    // console.log("Apply these filters", this.ganttFilterToolbox)
    this.filterBryntumGantt(this.ganttFilterToolbox)
    this.appliedFiltersCount = this.countAppliedFilters(this.ganttFilterToolbox)
  }

  filterBryntumGantt(ganttFilterToolbox: GanttFilterToolbox) {
    // console.log("Filter toolbox", ganttFilterToolbox);
    let taskStore = this.ganttInstance.taskStore;
    const {
      searchQuery,
      selectedStatus,
      selectedWorkstream,
      selectedTasktype,
      selectedRole,
      selectedOverdue,
      selectedCritical,
      selectedOwner,
      selectedPhase,
      selectedWorkpackage,
      selectedDateCombinationPlannedfrom,
      selectedPlannedfromDate,
      selectedDateCombinationPlannedto,
      selectedPlannedtoDate
    } = ganttFilterToolbox;

    // Clear existing filters before applying new ones
    taskStore.clearFilters();

    taskStore.filter({
      filterBy: (record: {
        name: string,
        fullStatus: any[],
        workstreamtype: string,
        tasktype: string,
        roletype: string,
        isOverDue: boolean,
        assignedTo: string,
        id: string,
        startDate: string,
        endDate: string,
        wbs: string, // Added wbs to the record
        critical: boolean
      }) => {
        let matchesSearchQuery = true;
        let matchesStatus = true;
        let matchesWorkstream = true;
        let matchesTasktype = true;
        let matchesRole = true;
        let matchesOverdue = true;
        let matchesCritical = true;
        let matchesOwner = true;
        let matchesPhase = true;
        let matchesWorkpackage = true;
        let matchesDateRange = true;

        // console.log("Record", record.critical, selectedWorkpackage)

        // Function to normalize date (ignore time)
        const normalizeDate = (date: Date) => {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        };

        // Apply searchQuery filter
        if (searchQuery.length > 2) {
          const searchTerm = searchQuery.toLowerCase();
          const name = record.name.toLowerCase();
          // const wbs = record.wbs.toLowerCase();
          matchesSearchQuery = name.includes(searchTerm);
        }

        // Apply selectedStatus filter
        if (selectedStatus && selectedStatus.length > 0) {
          matchesStatus = selectedStatus.some(status => status._id === record.fullStatus[0]?._id);
        }

        // Apply selectedWorkstream filter
        if (selectedWorkstream && selectedWorkstream.length > 0) {
          matchesWorkstream = selectedWorkstream.some(workstream => workstream._id === record.workstreamtype);
        }

        // Apply selectedTasktype filter
        if (selectedTasktype && selectedTasktype.length > 0) {
          matchesTasktype = selectedTasktype.some(tt => tt._id === record.tasktype);
        }

        // Apply selectedRole filter
        if (selectedRole && selectedRole.length > 0) {
          matchesRole = selectedRole.some(role => role._id === record.roletype);
        }

        // Apply overdue filter
        if (selectedOverdue) {
          matchesOverdue = record.isOverDue === true;
        }

        // Apply critical path filter
        if (selectedCritical) {
          matchesCritical = record.critical === true;
        }

        // Apply selectedOwner filter
        if (selectedOwner && selectedOwner.length > 0) {
          matchesOwner = selectedOwner.some(owner => owner.id === record.assignedTo);
        }

        // Apply selectedPhase filter
        if (selectedPhase && selectedPhase.length > 0) {
          matchesPhase = selectedPhase.some(phase => record.wbs.startsWith(`${phase.wbs}.`));
        }

        // Apply selectedWorkpackage filter
        if (selectedWorkpackage && selectedWorkpackage.length > 0) {
          matchesWorkpackage = selectedWorkpackage.some(wp => record.wbs.startsWith(`${wp.wbs}.`));
        }

        // Apply date range filter
        if (selectedPlannedfromDate) {
          const plannedFromDate = normalizeDate(new Date(selectedPlannedfromDate));
          const taskStartDate = normalizeDate(new Date(record.startDate));

          if (selectedDateCombinationPlannedfrom === "Greater than") {
            matchesDateRange = matchesDateRange && (taskStartDate > plannedFromDate);
          } else if (selectedDateCombinationPlannedfrom === "Less than") {
            matchesDateRange = matchesDateRange && (taskStartDate < plannedFromDate);
          } else if (selectedDateCombinationPlannedfrom === "Equal to") {
            matchesDateRange = matchesDateRange && (taskStartDate.getTime() === plannedFromDate.getTime());
          }
        }

        if (selectedPlannedtoDate) {
          const plannedToDate = normalizeDate(new Date(selectedPlannedtoDate));
          const taskEndDate = normalizeDate(new Date(record.endDate));

          if (selectedDateCombinationPlannedto === "Greater than") {
            matchesDateRange = matchesDateRange && (taskEndDate > plannedToDate);
          } else if (selectedDateCombinationPlannedto === "Less than") {
            matchesDateRange = matchesDateRange && (taskEndDate < plannedToDate);
          } else if (selectedDateCombinationPlannedto === "Equal to") {
            matchesDateRange = matchesDateRange && (taskEndDate.getTime() === plannedToDate.getTime());
          }
        }

        // Return true only if all filters match
        return matchesSearchQuery && matchesStatus && matchesWorkstream && matchesTasktype && matchesRole && matchesOverdue && matchesCritical && matchesOwner && matchesPhase && matchesWorkpackage && matchesDateRange;
      }
    });
  }

  countAppliedFilters(ganttFilterToolbox: any): number {
    let filterCount = 0;

    // Destructure the relevant keys from the ganttFilterToolbox
    const {
      searchQuery,
      selectedStatus,
      selectedWorkstream,
      selectedTasktype,
      selectedRole,
      selectedOverdue,
      selectedOwner,
      selectedPhase,
      selectedWorkpackage,
      selectedPlannedfromDate,
      selectedPlannedtoDate,
    } = ganttFilterToolbox;

    // Count searchQuery filter if its length is greater than 3
    if (searchQuery) {
      filterCount += 1;
    }

    // Count selectedStatus if it has any entries
    if (selectedStatus && selectedStatus.length > 0) {
      filterCount += 1;
    }

    // Count selectedWorkstream if it has any entries
    if (selectedWorkstream && selectedWorkstream.length > 0) {
      filterCount += 1;
    }

    // Count selectedTasktype if it has any entries
    if (selectedTasktype && selectedTasktype.length > 0) {
      filterCount += 1;
    }

    // Count selectedRole if it has any entries
    if (selectedRole && selectedRole.length > 0) {
      filterCount += 1;
    }

    // Count selectedOverdue if it is true
    if (selectedOverdue) {
      filterCount += 1;
    }

    // Count selectedOwner if it has any entries
    if (selectedOwner && selectedOwner.length > 0) {
      filterCount += 1;
    }

    // Count selectedPhase if it has any entries
    if (selectedPhase && selectedPhase.length > 0) {
      filterCount += 1;
    }

    // Count selectedWorkpackage if it has any entries
    if (selectedWorkpackage && selectedWorkpackage.length > 0) {
      filterCount += 1;
    }

    // Count selectedPlannedfromDate if it's a valid date
    if (selectedPlannedfromDate && !isNaN(Date.parse(selectedPlannedfromDate))) {
      filterCount += 1;
    }

    // Count selectedPlannedtoDate if it's a valid date
    if (selectedPlannedtoDate && !isNaN(Date.parse(selectedPlannedtoDate))) {
      filterCount += 1;
    }

    return filterCount;
  }


  fullScreen: boolean = false;
  enableFullscreen() {
    this.fullScreen = !this.fullScreen;

    const exitHandler = () => {
      if (
        document.fullscreenElement ||
        (document as any).mozFullScreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).msFullscreenElement
      ) {
        console.log("Full screen mode is active");
      } else {
        console.log("Exited full screen mode");
        // this.showFilters = true;
        // this.disableFilters = false;
        this.fullScreen = false;
      }
    };

    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    if (this.fullScreen) {
      // this.showFilters = false;
      // this.disableFilters = true;
      if (this.elemFullScreen[0].requestFullscreen) {
        this.elemFullScreen[0].requestFullscreen();
      } else if (this.elemFullScreen[0].mozRequestFullScreen) {
        /* Firefox */
        this.elemFullScreen[0].mozRequestFullScreen();
      } else if (this.elemFullScreen[0].webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elemFullScreen[0].webkitRequestFullscreen();
      } else if (this.elemFullScreen[0].msRequestFullscreen) {
        /* IE/Edge */
        this.elemFullScreen[0].msRequestFullscreen();
      }
    } else {
      // this.showFilters = true;
      // this.disableFilters = false;
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      }
    }
  }

  clearAllFilters() {
    console.log("Reset filters")
    this.clearFilterVars();
    this.ganttInstance.taskStore.clearFilters();
  }

  clearFilterVars() {
    this.appliedFiltersCount = 0;
    this.showOnlyOverdue = false;
    this.showOnlyCritical = false;
    this.searchTerm = "";
    this.statusSelected = [];
    this.phaseSelected = [];
    this.workpackageSelected = [];
    this.ownerSelected = [];
    this.workstreamSelected = [];
    this.roleSelected = [];
    this.tasktypeSelected = [];
    this.combinationSelectedPlannedfrom = "Equal to";
    this.plannedFromSelected = [];
    this.combinationSelectedPlannedto = "Equal to";
    this.plannedToSelected = [];

    this.clearFilterToolbox();
  }

  clearFilterToolbox() {
    this.ganttFilterToolbox = {
      searchQuery: "",
      selectedStatus: [],
      selectedWorkstream: [],
      selectedTasktype: [],
      selectedRole: [],
      selectedOverdue: false,
      selectedCritical: false,
      selectedOwner: [],
      selectedPhase: [],
      selectedWorkpackage: [],
      selectedDateCombinationPlannedfrom: "Equal to",
      selectedPlannedfromDate: "",
      selectedDateCombinationPlannedto: "Equal to",
      selectedPlannedtoDate: "",
    }
  }

  onSaveSuccess() {
    this.showSuccessMessage = true;
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 5000);
  }
 
  updateAbility(userAccessLevels) {
    const { can, rules } = new AbilityBuilder(Ability);

    const userAccessLevel = this.currentUser.user.accessLevel;
    const matchedAccessLevel = userAccessLevels.find(level => level.name.toLowerCase() === userAccessLevel.toLowerCase());
    console.log(userAccessLevel,matchedAccessLevel)
    if (matchedAccessLevel) {
      matchedAccessLevel.matrix.forEach(entry => {
        const { actions, subject, conditions } = entry;
        console.log(entry,"condition")
        actions.forEach(action => {
          if (conditions) {
            can(action, subject, conditions);
          } else {
            can(action, subject);
          }
        });
      });
    } else {
      can('read', 'Project Plan');
    }
    // console.log("rules",rules)
    this.ability.update(rules);
    this.isSaveButtonDisabled()
  }
  hasPermission(action: string, subject: string): boolean {
    return this.ability.can(action, subject);
  }
canSave:boolean=false
  // Example usage in the template to conditionally disable the button
   isSaveButtonDisabled() {
    this.canSave= this.hasPermission('update', 'Project Plan');
    console.log("this.canSave",this.canSave)
  }

}
