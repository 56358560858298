import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent implements OnInit {
  constructor(private auth: AuthService, private route: ActivatedRoute,) {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.projectID = queryParams["params"].project;
      console.log(this.projectID, "PROJECT ID")
    })
  }


  navigateToSimulation() {
    document.location.href = `${environment.oAuthUrl}/#/pages/project-hub/timeline/planner?project=${this.projectID}`;
  }

  isCollapsed = true;
  projectID: string;

  ngOnInit() {
    this.getuserName();
    console.log("INIT", this.getCookie('ktoken'))
    const token = this.getCookie('ktoken');
    console.log('Token:', token);
  }

  getInitials(name: string): string {
    if (name && name.length) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .substring(0, 2);
    } else {
      return "NA";
    }

  }

  projectsList: { _id: string; name: string }[] = [
    {
      _id: "66300cee23006656a617f366",
      name: "Motiva Quantum Leap"
    }
  ]

  selectedProject: any = {
    _id: "66300cee23006656a617f366",
    name: "Motiva Quantum Leap"
  }

  userName: string;

  getuserName() {
    this.userName = this.auth.getfullName()
  }

  //For getting the logged in user token from cookie storage
  getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();

      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }

    return null;
  }
}
