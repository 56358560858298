import { NgModule } from '@angular/core';

// Required Ant Design Modules
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@NgModule({
  exports: [
    NzLayoutModule,        // For Layout, Sider, Header
    NzButtonModule,        // For Buttons
    NzIconModule,          // For Icons
    NzSwitchModule,        // For Switch
    NzSelectModule,        // For Single Select and Multiselect Dropdowns
    NzAvatarModule,        // For Avatar
    NzNotificationModule,   // For Notifications
    NzDropDownModule,
    NzToolTipModule,
    NzSpinModule,
    NzAlertModule,
    NzMessageModule,
    NzBadgeModule,
    NzPopconfirmModule
  ]
})
export class AppNgZorroAntdModule {}
