import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlannerRoutingModule } from './planner-routing.module';
import { GanttModule } from './gantt/gantt.module';
import { PlannerComponent } from './planner.component';
import { AppNgZorroAntdModule } from '../../app-ng-zorro-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppPrimeNGModule } from '../../app-primeng.module';
import { ConfirmationService } from 'primeng/api';
import { AbilityModule } from '@casl/angular';

@NgModule({
  declarations: [
    PlannerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppNgZorroAntdModule,
    AppPrimeNGModule,
    PlannerRoutingModule,
    GanttModule, // Import other feature modules here as needed
    AbilityModule
  ],
  providers: [ConfirmationService], // Add ConfirmationService
})
export class PlannerModule { }
